import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { LoadingButton } from "@mui/lab";
import { formatUIDate } from 'shared/utils';

const SeeNote = ({ openSeeNote, setOpenSeeNote, selectedNote }) => {
  return (
    <Dialog open={openSeeNote}
      sx={{ "& .MuiPaper-root": { maxWidth: 700, minWidth: 400, minHeight: 300 } }}>
      <DialogTitle>
        <Typography variant="h5" gutterBottom>
          {selectedNote.title} - {formatUIDate(selectedNote.regDate)}
        </Typography>
      </DialogTitle>
      <DialogContent variant="body2" gutterBottom>
        {selectedNote.content}
      </DialogContent>
      <DialogActions>
        <LoadingButton variant="contained" onClick={() => setOpenSeeNote(false)}>Close</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default SeeNote;