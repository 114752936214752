import React, { useCallback, useEffect, useState } from "react";
import api from 'appConfig/restAPIs';
import axios from 'axios';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const SubscriberNoteHead = ({ customerId }) => {

  const [subscriberInfo, setSubscriberInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchSubscriberInfo = useCallback((customerId) => {
    setIsLoading(true);

    axios.get(`${api.subscriber}/${customerId}`)
      .then(({ data }) => {
        setSubscriberInfo(data);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, [])

  useEffect(() => {
    fetchSubscriberInfo(customerId);
  }, [customerId, fetchSubscriberInfo]);

  return (
    <>
      {
        subscriberInfo &&
        <Card sx={{ width: 780, mb:4 }}>
          <CardContent>
            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
              {subscriberInfo.customerId}
            </Typography>
            <Typography variant="h5" component="div">
              {subscriberInfo.companyEn}
            </Typography>
            <Typography variant="h5" component="div">
              {subscriberInfo.companyKo}
            </Typography>
            <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>
              {subscriberInfo.nameEn} {subscriberInfo.nameKo}
            </Typography>
            <Typography variant="body2">
              Home Phone: {subscriberInfo.hTel} | Business Phone: {subscriberInfo.bTel}
            </Typography>
          </CardContent>
        </Card>
      }
    </>

  )
}

export default SubscriberNoteHead;