import React from "react";
import { useParams } from 'react-router-dom';
import SubscriberNoteHead from './SubscriberNoteHead';
import SubscriberNoteList from './SubscriberNoteList';

const SubscriberNote = () => {
  const params = useParams();

  return (
    <>
      <SubscriberNoteHead customerId = {params.customerId}/>
      <SubscriberNoteList customerId={params.customerId} />
    </>
  )
}

export default SubscriberNote;