import React, { useCallback, useEffect, useState, useRef } from "react";
import { Box, Grid } from "@mui/material";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DetailsIcon from '@mui/icons-material/Details';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'assets/styles/table.css';
import axios from "axios";
import api from "appConfig/restAPIs";
import { LoadingButton } from "@mui/lab";
import SeeNote from "./SeeNote";
import CreateNote from "./CreateNote";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { styled } from '@mui/material/styles';
import ConfirmDialog from "shared/components/ConfirmDialog";

const IconLoadingButton = styled(LoadingButton)({
  "& .MuiButton-startIcon": {
    marginLeft: 0, marginRight: 0,
  }
});

const SubscriberNoteList = ({ customerId }) => {
  const gridRef = useRef();
  const [notes, setNotes] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openSeeNote, setOpenSeeNote] = useState(false);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [canEdit, setCanEdit] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);

  const columnDefs = [
    { field: 'subNoteId', headerName: 'ID', width: 100 },
    { field: 'title', headerName: 'Title', width: 240 },
    { field: 'content', headerName: 'Content', width: 600 },
    { field: 'regDate', headerName: 'Reg Date', width: 100 },
    { field: 'regBy', headerName: 'Reg By', width: 100 },
    // { field: 'updatedDate', headerName: 'Updated Date', width: 120 },
    // { field: 'updatedBy', headerName: 'Updated By', width: 120 }
  ];

  const fetchList = useCallback(() => {
    setIsLoading(true);
    axios.get(`${api.subNote}?customerId=${customerId}`)
      .then((resp) => {
        setNotes(resp.data);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, [customerId]);

  useEffect(() => {
    fetchList();
  }, [fetchList])

  const onSelectionChanged = useCallback(() => {
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    setSelectedNodes(selectedNodes);
  }, []);

  useEffect(() => {
    if(selectedNodes?.length === 1){
      setCanEdit(true);
    }else{
      setCanEdit(false);
    }
  }, [selectedNodes])

  const deleteNote = () => {
    let idx = selectedNodes[0].data.subNoteId;
    axios.put(`${api.subNote}/${idx}`)
      .then((resp) => {
        fetchList();
      })
      .finally(() => {
        setIsDeleteOpen(false);
      })
  }

  return (
    <>
      <ConfirmDialog open={isDeleteOpen}
        message={`Do you want to delete it?`}
        onOK={deleteNote} 
        onCancel={() => setIsDeleteOpen(false)}
        onClose={() => setIsDeleteOpen(false)} 
      />

      <Grid container direction="row" rowGap={4}>
        <Box sx={{ width: '100%', height: 600 }} className="ag-theme-alpine">
          <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 1 }} wrap="noWrap">
            <Grid item columnGap={3} alignItems="center">
              <IconLoadingButton startIcon={<NoteAddIcon />} variant="contained" onClick={() => setIsCreateOpen(true)}>
                Create a note
              </IconLoadingButton>
              <IconLoadingButton startIcon={<DetailsIcon />} variant="contained" onClick={() => setOpenSeeNote(true)} disabled={!canEdit} sx={{mr:2, ml:2}}>
                See a note
              </IconLoadingButton>
              <IconLoadingButton startIcon={<DeleteForeverIcon />} variant="contained" onClick={() => setIsDeleteOpen(true)} disabled={!canEdit}>
                Delete a note
              </IconLoadingButton>
            </Grid>
          </Grid>
          <AgGridReact
            ref={gridRef}
            rowData={notes}
            columnDefs={columnDefs}
            defaultColDef={{
              sortable: true,
              resizable: true,
              filter: true,
            }}
            getRowId={params => params.data.subNoteId}
            rowSelection="single"
            onSelectionChanged={onSelectionChanged}
            pagination={true}
            paginationPageSize={20}
          >
          </AgGridReact>
        </Box>
      </Grid>
      {isCreateOpen && <CreateNote isCreateOpen={isCreateOpen} setIsCreateOpen={setIsCreateOpen} fetchList={fetchList} customerId={customerId} />}
      {openSeeNote && <SeeNote openSeeNote={openSeeNote} setOpenSeeNote={setOpenSeeNote} selectedNote = {selectedNodes[0].data} />}
    </>
  )
}

export default SubscriberNoteList;